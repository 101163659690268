<template>
  <periodic-performance
    v-model="leadDealer"
    :title="title"
  />
</template>

<script>
export default {
  components: {
    PeriodicPerformance: () => import(/* webpackChunkName: "periodicPerformance" */ '@/components/PeriodicPerformance'),
  },
  data () {
    return {
      leadDealer: 'md',
      title: 'MD Performance',
    }
  },
}
</script>
